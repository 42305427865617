import { useState } from 'react';
import { loginService } from '../service/UserService';
//import '../css/Login.css'
import { useNavigate } from "react-router-dom";

function Login() {

    const[formData, setFormData] = useState({
        username: '',
        password: '',
        errors: {},        
    });

    const navigate = useNavigate();

    const inputChangeHandler = (e) => {
        const{ name,value } = e.target;
        setFormData((prevState) => ({...prevState,[name]: value}));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(validation()){

            let loginRequest = {
            username: formData.username,
            password: formData.password
            }

            
            loginService(loginRequest).then((res) => {
                if(res != null){

                    let data =  {
                        username : loginRequest.username,
                        name : 'Kasun Perera',                       
                        role: res.data.role,                       
                        token : res.data.token
                    }
                    
                    sessionStorage.setItem('x$sk72hyQwersgyhk',btoa(JSON.stringify(data))); //logged user data
                    navigate('/loading')

                }else{

                    const errors = {};
                    errors.login = 'Invalid username or password.';
                    setFormData((prevState) => ({...prevState, errors}));
                }
            });
        
        } else{
            console.log("invalid")
        }
    }

    const validation = () => {
        const errors = {};

        if(!formData.username){
            errors.username = 'Username is required';
        }

        if(!formData.password){
            errors.password = 'Password is required';
        }   
        
        setFormData((prevState) => ({...prevState, errors}));

        // return true if there are no errors
        return Object.keys(errors).length === 0;
    }
    
    return (
        <div className='wrapper bg-master_color-600'>
            <div className='box'>
                
            </div>
            <div className='flex flex-col h-screen justify-center items-center' >            
                <h1 className='text-center text-blue-100 text-3xl title'>ZARA MARKETING (PVT) LTD</h1>
                <div className='flex flex-row justify-center items-center mt-3'>
                    <div className='bg-white w-[450px] rounded-lg p-6'> 
                        <form>                  
                            <div className="relative flex items-center">
                                <input type='text' 
                                    name='username'
                                    value={formData.username}
                                    onChange={inputChangeHandler}
                                    placeholder='Username'
                                    className="pr-4 pl-[70px] py-2.5  text-black rounded-lg bg-white border-2 border-gray-200 w-full outline-none" 
                                />
                                <div className="absolute bg-slate-400 w-14 flex items-center justify-center rounded-tl-lg rounded-bl-lg h-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" fill="#fff" viewBox="0 0 512 512">
                                        <path d="M437.02 74.981C388.667 26.629 324.38 0 256 0S123.333 26.629 74.98 74.981C26.629 123.333 0 187.62 0 256s26.629 132.667 74.98 181.019C123.333 485.371 187.62 512 256 512s132.667-26.629 181.02-74.981C485.371 388.667 512 324.38 512 256s-26.629-132.667-74.98-181.019zM256 482c-66.869 0-127.037-29.202-168.452-75.511C113.223 338.422 178.948 290 256 290c-49.706 0-90-40.294-90-90s40.294-90 90-90 90 40.294 90 90-40.294 90-90 90c77.052 0 142.777 48.422 168.452 116.489C383.037 452.798 322.869 482 256 482z" data-original="#000000"></path>
                                    </svg>
                                </div>                            
                            </div>                                    

                            <div className="relative flex items-center mt-3">
                                <input type='password'
                                    name='password'
                                    value={formData.password}
                                    onChange={inputChangeHandler}
                                    placeholder='Password'
                                    className="pr-4 pl-[70px] py-2.5  text-black rounded-lg bg-white border-2 border-gray-200 w-full outline-none" 
                                />
                                <div className="absolute bg-slate-400 w-14 flex items-center justify-center rounded-tl-lg rounded-bl-lg h-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#fff" width="22px" height="22px">
                                        <path fill-rule="evenodd" d="M8 1a3.5 3.5 0 0 0-3.5 3.5V7A1.5 1.5 0 0 0 3 8.5v5A1.5 1.5 0 0 0 4.5 15h7a1.5 1.5 0 0 0 1.5-1.5v-5A1.5 1.5 0 0 0 11.5 7V4.5A3.5 3.5 0 0 0 8 1Zm2 6V4.5a2 2 0 1 0-4 0V7h4Z" clip-rule="evenodd" />
                                    </svg>
                                </div>
                            </div>

                            <div className="relative flex mt-3 justify-center">
                                <input type='submit' 
                                    value='LOGIN'
                                    onClick={handleSubmit}
                                    className="bg-master_color-700 hover:bg-master_color-500 hover:font-semibold text-white py-2.5 px-4 rounded-md w-full cursor-pointer" 
                                />                       
                            </div> 
                                                
                            <div className={`p-1 mt-2 text-sm text-center text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 ${Object.keys(formData.errors).length === 0 ? 'hidden':'' }`} role="alert">
                                {formData.errors.username && (<span class="font-medium">{formData.errors.username}&nbsp;&nbsp;</span>)} 
                                {formData.errors.password && (<span class="font-medium">{formData.errors.password}</span>)}
                                {formData.errors.login && (<span class="font-medium">{formData.errors.login}</span>)}                            
                            </div>
                        </form>         
                    </div>
                </div>
            </div>
        </div>        
    );

}

export default Login;