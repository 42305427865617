import { useNavigate } from "react-router-dom";

function InfoBar() {   
    
    const loggedUser = JSON.parse(atob(sessionStorage.getItem('x$sk72hyQwersgyhk')))

    const navigate = useNavigate();

    const handleLogoutButton = () => {
        sessionStorage.removeItem('x$sk72hyQwersgyhk');
        navigate('/')
    }
        
    return ( 
        <div className="h-6 w-full bg-yellow-500  flex flex-col flex-wrap">
            <div className="ml-2 font-semibold tracking-wider">
            <span>{loggedUser.role}</span>&nbsp;|&nbsp;
            <span>{loggedUser.name}</span>
            </div>               
            <div className="ml-auto pr-6 font-semibold tracking-wider">                
                <span
                 className="hover:text-gray-500 cursor-pointer"                 
                >
                    <button 
                        onClick={handleLogoutButton}                   
                    >
                     Logout
                    </button>                   
                </span>
            </div>
        </div>
    );
}

export default InfoBar;