import { useEffect, useState, useRef, useMemo, useCallback} from "react";
import { AgGridReact} from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

import DataTable from "react-data-table-component";
import { FaCaretLeft, FaCaretRight, FaCircle, FaDownload, FaPlus, FaSquare } from "react-icons/fa6";
import Notification from "../component/utility/Notification";
import { activateItem, deActivateItem, getItemById, getItemList, saveItem, updateItem } from "../service/ItemService";
import { getAllActiveCategory } from "../service/CategoryService";

function Item() {
    const loggedUser = JSON.parse(atob(sessionStorage.getItem('x$sk72hyQwersgyhk'))) 

    const [formData, setFormData] = useState({
        id: '',
        name: '',
        description: '',
        imagePath: '',
        warranty: '',
        active: 'ACTIVE',        
        catId: 0,
        errors: {},
    });
    
    // Main Layout
    const[toggle,setToggle] = useState(0)
    const[toggleIcon,setToggleIcon] = useState(<FaCaretRight size={36}/>);
    const[style,setStyle] = useState("w-1/5");
    

    // DataTable
    const gridRef = useRef();
    const[colHide, setColHide] = useState(false);    
    const [rowData, setRowData] = useState([]); // Row Data: The data to be displayed. 
    
    
    const [colDefs, setColDefs] = useState([
        { colId: 'id', valueGetter: p => p.data.id , flex:1, hide: true},
        { colId: 'index', headerName: "#", valueGetter: p => p.data.index , flex:0.3},
        { colId: 'name', headerName: "NAME", valueGetter: p => p.data.name , filter: true , flex:1},
        { colId: 'category', headerName: "CATEGORY", valueGetter: p => p.data.category.name , filter: true ,  flex:1, hide: true},        
        { colId: 'active', headerName: "STATUS", valueGetter: p => p.data.active , filter: true , flex:1, hide: true},
                      
    ]);     

    //////////////// functions related MainLayout ////////////////////////////////////////////////////////////////   

    const toggleLayout = () =>{
        if(toggle === 0) setToggle(1);
        else setToggle(0);       

        if(toggle !== 0) setStyle("w-1/5");
        else setStyle("w-screen");

        if(toggle !== 0) setToggleIcon(<FaCaretRight size={36}/>);
        else setToggleIcon(<FaCaretLeft size={36}/>);

        if(toggle !== 0) setColHide(false);
        else  setColHide(true);
        
        if(toggle !==0) {
            gridRef.current.api.setColumnVisible('category', false);
            gridRef.current.api.setColumnVisible('description', false);
            gridRef.current.api.setColumnVisible('active', false);
        }
        else{
            gridRef.current.api.setColumnVisible('category', true);
            gridRef.current.api.setColumnVisible('description', true);
            gridRef.current.api.setColumnVisible('active', true);
        } 


    }

    //////////////// functions related DataTable ////////////////////////////////////////////////////////////////     

    const handledOnRowClick = useCallback(() => {
        const selectedRows = gridRef.current.api.getSelectedRows();        

        if(selectedRows.length === 1){
            setFormData((prevState) =>({...prevState,
                id: selectedRows[0].id,
                name: selectedRows[0].name,
                description: selectedRows[0].description,
                imagePath: selectedRows[0].imagePath,
                warranty: selectedRows[0].warranty,
                active: selectedRows[0].active,        
                catId: selectedRows[0].category.id,              
            })) 
    
            setOperation('update');
            setResponse({status: '' , message: 'Finished loading : '+selectedRows[0].name});
            setItemStatus(selectedRows[0].active);
            setIsValidInputs(true)
        }        

      },[]);
    
    

    const handledOnRowDoubleClick = (row,event) =>{
        setToggle(0);
        setStyle("w-1/5");
        setToggleIcon(<FaCaretRight size={25}/>);
        setColHide(false);        
        handledOnRowClick();
        toggleLayout();        
    }    
    
    
    
    const fillItemDataTable = async() =>{
        try{
            const req = await getItemList(loggedUser.token);  
            for(let i = 0 ; i < req.data.length ; i++){
                req.data[i].index = i+1;
            }  
                      
            setRowData(req.data)
            
        } catch(error){
           console.log(error);
        }
    }

    useEffect(()=>{
        fillItemDataTable();
    },[]);   

    

    //////////////// functions related TabLayout ////////////////////////////////////////////////////////////////

    // Tab Layout
    const[tab_1_Hide, setTab_1_Hide] = useState('');
    const[tab_2_Hide, setTab_2_Hide] = useState('hidden');
    const[tab_3_Hide, setTab_3_Hide] = useState('hidden');

    const TAB_ACTIVE_CSS = 'text-master_color-400 border-master_color-400';
    const[tab_1_active_css,setTab_1_active_css] = useState(TAB_ACTIVE_CSS);
    const[tab_2_active_css,setTab_2_active_css] = useState('');
    const[tab_3_active_css,setTab_3_active_css] = useState('');
     
    const toggleTab = (id) =>{
        
        if(id === 1 ){
            setTab_1_Hide('');
            setTab_2_Hide('hidden');
            setTab_3_Hide('hidden');

            setTab_1_active_css(TAB_ACTIVE_CSS);
            setTab_2_active_css('');
            setTab_3_active_css('');
        }
        
        if(id === 2 ){
            setTab_1_Hide('hidden');
            setTab_2_Hide('');
            setTab_3_Hide('hidden');

            setTab_1_active_css('');
            setTab_2_active_css(TAB_ACTIVE_CSS);
            setTab_3_active_css('');
        }

        if(id === 3 ){
            setTab_1_Hide('hidden');
            setTab_2_Hide('hidden');
            setTab_3_Hide('');

            setTab_1_active_css('');
            setTab_2_active_css('');
            setTab_3_active_css(TAB_ACTIVE_CSS);
        }

    }
    
    //////////////// functions related input componnts ///////////////////////////////////////////////////////////
    
    const [activeCategoryList, setActiveCategoryList] = useState([])

    // for category dropdown
    const getActiveCategoryList = async() =>{ 
        try{
            const req = await getAllActiveCategory(loggedUser.token);  
            setActiveCategoryList(req.data)
        } catch(error){
           console.log(error);
        }
    }

    // set category list to dropdown
    useEffect(()=>{
        getActiveCategoryList();
    },[]);


    const inputChangeHandler = (e) => {
        const{ name,value } = e.target;
        setFormData((prevState) => ({...prevState,[name]: value}));
    }

    // for validation
    const [isValidName, setIsValidName] = useState(true)
    const [isValidCatId, setIsValidCatId] = useState(true)
    const [isValidWarranty, setIsValidWarranty] = useState(true)
    

    // clear inputs
    const clearInputs = () =>{
        setFormData({
            id: '',
            name: '',
            description: '',
            imagePath: '',
            warranty: '',
            active: 'ACTIVE',        
            catId: 0,
            errors: {},
        });

        setOperation('new')
    }

    // input and state initialize
    const initialize = () =>{
        clearInputs();
        setIsValidInputs(true);
        setItemStatus('ACTIVE');       
    }

    // change input validation states
    const setIsValidInputs = (isValid) =>{
        setIsValidName(isValid)
        setIsValidCatId(isValid)
    }

    // input validation
    const inputValidation = () =>{
        const errors ={};

        if (formData.name === '' || formData.name.length === 0){
            setIsValidName(false)
            errors.name = 'Name is required';
        }

        if (formData.catId === '' || formData.catId === 0){            
            setIsValidCatId(false)
            errors.category = 'Category is required';                     
        }

        setFormData((prevState) => ({...prevState, errors}));
        // return true if there are no errors
        return Object.keys(errors).length === 0;
    }

    
    //////////////// functions related action buttons ///////////////////////////////////////////////////////////

    const [operation, setOperation] = useState('new'); // database operation -> new,update
    
    const [response, setResponse] = useState({ //status: true, data: null, message: 'Already added : SR Code(104)'
        status: '',
        message: ''
    })
    
    const [itemStatus, setItemStatus] = useState('ACTIVE'); // for operate active button
    
    
    const handleNewBtn = () => {
        initialize();       
        setResponse({status: '' , message: ''})              
    }

    const handleSaveBtn = async(e) =>{
        e.preventDefault();
        setIsValidInputs(true);
        
        
        let item = {
            id: formData.id,
            name: formData.name.toUpperCase(),
            description: formData.description,
            imagePath: 'https://png.pngtree.com/png-vector/20190115/ourmid/pngtree-vector-cloud-upload-icon-png-image_316794.jpg',
            warranty: formData.warranty,
            active: formData.active,        
            catId: formData.catId,
        }


        console.log(item.warranty)

        let res = null
        if(inputValidation()){
            if(operation === 'new'){
                res = await saveItem(loggedUser.token,formData.catId,item)
                setResponse({status: res.status , message: res.message})
                if(res.status){
                    clearInputs();
                }
            }else if(operation === 'update'){
                res = await updateItem(loggedUser.token,formData.catId,item)               
                setResponse({status: res.status , message: res.message})
                if(res.status){
                    clearInputs();
                }
            }else {                
                setResponse({status: false , message: 'Error'})
            }
        }else{
            setResponse({status: false , message: 'Error saving : Invalid inputs'})
        } 
        fillItemDataTable();
    }

    const handleActiveBtn = async(e) =>{
        e.preventDefault();
        let res = null
        
        if(itemStatus === 'ACTIVE'){
            res = await deActivateItem(loggedUser.token,formData.id);
            setResponse({status: res.status , message: res.message})
            setItemStatus('INACTIVE')
        }

        if(itemStatus === 'INACTIVE'){
            res = await activateItem(loggedUser.token,formData.id);
            setResponse({status: res.status , message: res.message})
            setItemStatus('ACTIVE')
        }
        
        fillItemDataTable();
    }


  return (
    <div className="flex h-full">
        <div className="w-1/5 z-10">
            <div className={`bg-white absolute top-0 bottom-0 ${style} border-solid border-r border-slate-300`}>                    
                <div className="grid grid-cols-2">
                    <div className="col-start-1 mt-1.5 ml-2.5">
                        <p className="text-master_color-400 font-semibold text-lg font-sans drop-shadow">Item List</p>
                    </div>
                    <div className="col-start-2 flex justify-end">
                        <button
                            onClick={toggleLayout} 
                            className="text-master_color-400 font-bold rounded mt-1 mr-1"> 
                            {toggleIcon}                          
                        </button>
                    </div>
                    <div className="col-span-2 p-0">                      
                    </div>                        
                                                    
                </div>
                <hr className="border-slate-300 border-0.5 ml-0.5 mr-0.5"></hr>

                <div className={`h-[calc(100%_-_2.5rem)] p-2`}>                    
                    <div className="ag-theme-quartz h-full">
                        <AgGridReact 
                            ref={gridRef}                          
                            columnDefs={colDefs}                            
                            rowData={rowData}
                            pagination ={colHide}
                            paginationPageSize={10}
                            paginationPageSizeSelector={[5,10,15]}
                            defaultColDef={  
                                useMemo(() => ({suppressMovable: true,}), [])
                            }
                            rowSelection={"single"}
                            onSelectionChanged={handledOnRowClick}
                            onRowDoubleClicked={handledOnRowDoubleClick}
                            
                        />
                    </div>
                </div>

            </div>
        </div>


        <div className="w-4/5 z-0">              
            <div className="flex gap-1.5 pl-2">
                <div className="mt-1">
                    <button 
                        type="button"
                        onClick={handleNewBtn} 
                        className="px-4 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
                    >
                        <FaPlus size={15}/>
                        &nbsp;New
                    </button>
                </div>        
                <div className="mt-1">
                    <button 
                        type="button"
                        disabled={itemStatus === 'ACTIVE'? false:true}
                        onClick={handleSaveBtn}  
                        className="px-4 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
                    >
                        <FaDownload size={15}/>
                        &nbsp;Save
                    </button>
                </div> 
                <div className="mt-1">
                    <button 
                        type="button"
                        disabled={operation === 'new' ? true:false} 
                        onClick={handleActiveBtn} 
                        className="w-28 px-3 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
                    >
                        {itemStatus === 'ACTIVE' ? <FaSquare size={12}/>:<FaCircle size={12}/>}                            
                        &nbsp;
                        {itemStatus === 'ACTIVE' ? 'Deactivate':'Reactivate'}
                    </button>
                </div>
                <div className="mt-3 ml-6 flex w-auto">
                    <Notification response={response}/>
                </div>                                                    
            </div>
            <hr className="border-slate-300 border-0.5 mt-1"></hr>
                            
            <div id="default-styled-tab-content" className="h-[calc(100%_-_6.5rem)]" >
                <div className={`p-6 pt-1 ${tab_1_Hide}`} id="styled-profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div className='grid grid-cols-3 gap-12'>
                        <div>
                            <div className='text-master_color-300 text-lg font-semibold drop-shadow-sm'>Basic</div>
                            <div className='grid grid-cols-3 mt-1'>
                                <div className='pt-0.5 font-sans drop-shadow'>Item Name</div>
                                <div className='col-span-2'>
                                    <input 
                                        type='text' 
                                        name='name'
                                        value={formData.name}
                                        onChange={inputChangeHandler}
                                        disabled={itemStatus === 'ACTIVE'? false:true}
                                        placeholder='Required'                                                                                           
                                        className={`w-full text-sm pl-2 py-1 outline-none border  focus:border-master_color-200 rounded
                                        ${isValidName ? 'border-slate-400':'border-red-600'}
                                        ${itemStatus === 'ACTIVE'? 'bg-white':'bg-gray-200'} 
                                        `}                                       
                                    />
                                </div>
                            </div>
                            <div className='grid grid-cols-3 mt-2'>
                                <div className='pt-0.5 font-sans drop-shadow-sm'>Category</div>
                                <div className='col-span-2'>
                                    <select
                                        name='catId'
                                        value={formData.catId}                                        
                                        onChange={inputChangeHandler}
                                        disabled={itemStatus === 'ACTIVE'? false:true}   
                                        className={`w-full pl-1 py-1 text-sm outline-none border focus:border-master_color-200 rounded 
                                        ${isValidCatId ? 'border-slate-400':'border-red-600'}
                                        ${itemStatus === 'ACTIVE'? 'bg-white':'bg-gray-200'}
                                        `} 
                                    >
                                        <option value='0' disabled>-- select category --</option>
                                        {
                                            activeCategoryList.map((category)=>(
                                                <option key={category.id} value={category.id}>
                                                    {category.name}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='grid grid-cols-3 mt-2'>
                                <div className='pt-0.5 font-sans drop-shadow-sm'>Warranty</div>
                                <div className='col-span-2'>
                                    <select
                                        name='warranty'
                                        value={formData.warranty}                                        
                                        onChange={inputChangeHandler}
                                        disabled={itemStatus === 'ACTIVE'? false:true}   
                                        className={`w-full pl-1 py-1 text-sm outline-none border border-slate-400 focus:border-master_color-200 rounded 
                                        ${isValidWarranty ? 'border-slate-400':'border-red-600'}
                                        ${itemStatus === 'ACTIVE'? 'bg-white':'bg-gray-200'}
                                        `} 
                                    >
                                        <option value='0' disabled>-- select --</option>
                                        <option value='No'>No</option>
                                        <option value='Yes'>Yes</option>                                        
                                    </select>
                                </div>
                            </div>

                        </div>

                        <div>
                            <div className='text-master_color-300 text-lg font-semibold drop-shadow-sm'>Picture</div>
                            <div className='grid grid-cols-3 mt-1'>                                
                                <div className='col-span-2  text-center pt-5 text-red-300'>
                                    Temporary Disabled
                                </div>
                            </div>
                        </div>

                        <div></div>

                        <div className='col-span-2'>
                            <div className='text-master_color-300 text-lg font-semibold drop-shadow-sm'>Description</div>                                                        
                                <div className='mt-2'>
                                    <textarea 
                                        type='text' 
                                        name='description'
                                        value={formData.description}
                                        onChange={inputChangeHandler}
                                        disabled={itemStatus === 'ACTIVE'? false:true}                                                                                                                                  
                                        className={`w-full h-24 text-base pl-2 py-1 outline-none border border-slate-400 focus:border-master_color-200 rounded                                       
                                        ${itemStatus === 'ACTIVE'? 'bg-white':'bg-gray-200'} 
                                        `}                                       
                                    />
                                </div>                            
                        </div>                       
                    </div>
                </div>

                <div className={`p-4 pt-1 ${tab_2_Hide}`} id="styled-dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                    <p class="text-sm text-gray-500 dark:text-gray-400">This is some placeholder content the <strong class="font-medium text-gray-800 dark:text-white">Dashboard tab's associated content</strong>. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling.</p>
                </div>
                
                <div className={`p-4 pt-1 ${tab_3_Hide}`} id="styled-settings" role="tabpanel" aria-labelledby="settings-tab">
                    <p class="text-sm text-gray-500 dark:text-gray-400">This is some placeholder content the <strong class="font-medium text-gray-800 dark:text-white">Settings tab's associated content</strong>. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling.</p>
                </div>                
            </div>

            <div class="mb-4 border-b border-gray-200">
                <ul class="flex flex-wrap -mb-px text-sm font-medium text-center" 
                    id="default-styled-tab" 
                    data-tabs-toggle="#default-styled-tab-content" 
                    data-tabs-active-classes="text-master_color-400 hover:text-master_color-400 border-master_color-400 " 
                    data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300" 
                    role="tablist">
                    <li class="me-2" role="presentation">
                        <button 
                            className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 drop-shadow-sm ${tab_1_active_css}`} 
                            id="profile-styled-tab" 
                            data-tabs-target="#styled-profile" 
                            type="button" role="tab" 
                            aria-controls="profile" 
                            aria-selected="false"
                            onClick={()=>toggleTab(1)}>
                                Item info
                        </button>
                    </li>
                    <li class="me-2" role="presentation">
                        <button 
                            className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 ${tab_2_active_css}`} 
                            id="dashboard-styled-tab" 
                            data-tabs-target="#styled-dashboard" 
                            type="button" role="tab" 
                            aria-controls="dashboard" 
                            aria-selected="false"
                            onClick={()=>toggleTab(2)}>
                                
                        </button>
                    </li>
                    <li class="me-2" role="presentation">
                        <button 
                            className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 ${tab_3_active_css}`} 
                            id="settings-styled-tab" 
                            data-tabs-target="#styled-settings" 
                            type="button" 
                            role="tab" 
                            aria-controls="settings" 
                            aria-selected="false"
                            onClick={()=>toggleTab(3)}>
                                
                        </button>
                    </li>                    
                </ul>
            </div>


            
        </div>
    </div>
  )
}

export default Item;