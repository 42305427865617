import NavBar from "./NavBar";
import InfoBar from "./InfoBar";

function Header({message}) {
    return ( 
        <div>
            <NavBar/>
            <InfoBar message={message}/>
        </div>
    );
}

export default Header;