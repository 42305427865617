function Logo() {
    return ( 
        <div href="#" className="p-2 mr-4 inline-flex items-center">                        
            <span className="text-sm text-white font-semibold uppercase tracking-wide w-4/5">
               Zara Marketing Private Limited
            </span>
        </div>     
    );
}

export default Logo;