import { useRef, useState, useEffect, useCallback, useMemo } from "react";

import {
  FaCaretLeft,
  FaCaretRight,
  FaPlus,
  FaCircleXmark,
  FaSquare,
  FaCircle,
} from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import Notification from "../component/utility/Notification";

import {
  activateRoute,
  deActivateRoute,
  deleteRoute,
  getRouteList,
  saveRoute,
  updateRoute,
} from "../service/RouteService";

export default function Route() {
  const loggedUser = JSON.parse(
    atob(sessionStorage.getItem("x$sk72hyQwersgyhk"))
  );

  /****** Left Pane - start *****************************************************************************************************************/
  const [leftPaneStyle, setLeftPaneStyle] = useState("w-1/5");
  const [isLeftPaneExpanded, setIsLeftPaneExpanded] = useState(false);
  const [leftPaneExpandedButtonIcon, setLeftPaneExpandedButtonIcon] = useState(
    <FaCaretRight size={36} />
  );

  //++++ Route List DataTable  ++++++++++++++++++++++++++++++++
  const routeTableRef = useRef();
  const [paginationlHide, setPaginationlHide] = useState(true);
  const [routeTableRowData, setRouteTableRowData] = useState([]);

  const [routeTableColumnDefs, setRouteTableColumnDefs] = useState([
    { colId: "id", valueGetter: (r) => r.data.id, flex: 1, hide: true },
    {
      colId: "index",
      headerName: "#",
      valueGetter: (r) => r.data.index,
      flex: 0.3,
    },
    {
      colId: "name",
      headerName: "Name",
      valueGetter: (r) => r.data.name,
      filter: true,
      flex: 1,
    },
    {
      colId: "code",
      headerName: "Code",
      valueGetter: (r) => r.data.code,
      filter: true,
      flex: 1,
      hide: true,
    },
    {
      colId: "createdAt",
      headerName: "Created Date",
      valueGetter: (r) => r.data.createdAt.replace("T", " "),
      flex: 1,
      hide: true,
    },
    {
      colId: "active",
      headerName: "Status",
      valueGetter: (r) => r.data.active,
      filter: true,
      flex: 1,
      hide: true,
    },
  ]);

  // functions
  const handleExpandLeftPane = () => {
    if (!isLeftPaneExpanded) setIsLeftPaneExpanded(true);
    else setIsLeftPaneExpanded(false);

    if (isLeftPaneExpanded) {
      setLeftPaneStyle("w-1/5");
      setLeftPaneExpandedButtonIcon(<FaCaretRight size={36} />);

      routeTableRef.current.api.setColumnsVisible(
        ["code", "createdAt", "active"],
        false
      );
      setPaginationlHide(true);
    } else {
      setLeftPaneStyle("w-screen");
      setLeftPaneExpandedButtonIcon(<FaCaretLeft size={36} />);

      routeTableRef.current.api.setColumnsVisible(
        ["code", "createdAt", "active"],
        true
      );
      setPaginationlHide(false);
    }
  };

  const fetchRouteTableData = async () => {
    try {
      const res = await getRouteList(loggedUser.token);

      if (res !== undefined) {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].index = i + 1;
        }
        setRouteTableRowData(res.data);
      } else {
        setRouteTableRowData([]);
      }
    } catch (error) {
      console.error("Route -> fetchRouteTableData: ", error);
    }
  };

  useEffect(() => {
    fetchRouteTableData();
  }, []);

  const handledOnRowClick = useCallback(() => {
    const selectedRows = routeTableRef.current.api.getSelectedRows();

    if (selectedRows.length === 1) {
      setRouteFormData((prevState) => ({
        ...prevState,
        id: selectedRows[0].id,
        name: selectedRows[0].name,
        code: selectedRows[0].code,
        active: selectedRows[0].active,
      }));

      setOperationMode("update");
      setApiResponse({
        status: "",
        message: "Finished loading : " + selectedRows[0].name,
      });
      setRouteStatus(selectedRows[0].active);
      setIsValidInputs(true);
    }
  }, []);

  /****** Left Pane - end *******************************************************************************************************************/

  /****** Right Pane - start ****************************************************************************************************************/

  //++++ Button Container  ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [operationMode, setOperationMode] = useState("new"); // "new", "save", or "update"
  const [apiResponse, setApiResponse] = useState({
    //status: true, data: null, message: 'Already added : Fabric'
    status: "",
    message: "",
  });

  const [routeStatus, setRouteStatus] = useState("Active");

  const handleNewButton = () => {
    initialize();
    setApiResponse({ status: "", message: "Add new route" });
  };

  const handleSaveButton = async (e) => {
    e.preventDefault();
    setIsValidInputs(true);

    let route = {
      id: routeFormData.id,
      name: routeFormData.name,
      code: routeFormData.code.toUpperCase(),
      active: routeFormData.active,
    };

    let resRoute = null;

    if (isValidInputs()) {
      if (operationMode === "new") {
        resRoute = await saveRoute(loggedUser.token, route);

        setApiResponse({ status: resRoute.status, message: resRoute.message });

        if (resRoute != null) {
          if (resRoute.status) {
            initialize();
          }
        }
      } else if (operationMode === "update") {
        resRoute = await updateRoute(loggedUser.token, route);

        setApiResponse({ status: resRoute.status, message: resRoute.message });

        if (resRoute != null) {
          if (resRoute.status) {
            initialize();
          }
        }
      } else {
        setApiResponse({ status: false, message: "Error" });
      }
    } else {
      setApiResponse({
        status: false,
        message: "Error saving : Invalid inputs",
      });
    }

    fetchRouteTableData();
  };

  const handleDeleteButton = async (e) => {
    e.preventDefault();
    let res = null;
    if (routeFormData.name !== "" || routeFormData.name.length !== 0) {
      res = await deleteRoute(loggedUser.token, routeFormData.id);
      setApiResponse({ status: res.status, message: res.message });
      if (res.status) {
        initialize();
      } else {
        setApiResponse({ status: false, message: "Error deleting..." });
      }
    }

    fetchRouteTableData();
  };

  const handleActiveButton = async (e) => {
    e.preventDefault();
    let res = null;

    if (routeStatus === "Active") {
      res = await deActivateRoute(loggedUser.token, routeFormData.id);
      setApiResponse({ status: res.status, message: res.message });
      setRouteStatus("Inactive");
    }

    if (routeStatus === "Inactive") {
      res = await activateRoute(loggedUser.token, routeFormData.id);
      setApiResponse({ status: res.status, message: res.message });
      setRouteStatus("Active");
    }

    fetchRouteTableData();
  };

  //++++ Route info Tab Pane  ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Basic Inputs
  const [routeFormData, setRouteFormData] = useState({
    id: "",
    name: "",
    code: "",
    active: "Active",
    errors: {},
  });

  const handleBasicInputChange = (e) => {
    const { name, value } = e.target;
    setRouteFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Validation
  const [isValidName, setIsValidName] = useState(true);

  const isValidInputs = () => {
    const errors = {};

    if (routeFormData.name === "" || routeFormData.name.length === 0) {
      setIsValidName(false);
      errors.code = "Name is Required";
    }

    setRouteFormData((prevState) => ({ ...prevState, errors }));
    // return true if there are no errors
    return Object.keys(errors).length === 0;
  };

  // change input validation state
  const setIsValidInputs = (isValid) => {
    setIsValidName(isValid);
  };

  // clear inputs
  const clearFormInputs = () => {
    setRouteFormData({
      id: "",
      name: "",
      code: "",
      active: "Active",
      errors: {},
    });
  };

  // initialize inputs and state
  const initialize = () => {
    //basic
    clearFormInputs();
    setOperationMode("new");
    setIsValidInputs(true);
    setRouteStatus("Active");
  };

  //++++ Customer List Tab Pane  ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  //++++ Tab Layout  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [tab_1_Hide, setTab_1_Hide] = useState("");
  const [tab_2_Hide, setTab_2_Hide] = useState("hidden");
  const [tab_3_Hide, setTab_3_Hide] = useState("hidden");

  const TAB_ACTIVE_CSS = "text-master_color-400 border-master_color-400";
  const [tab_1_active_css, setTab_1_active_css] = useState(TAB_ACTIVE_CSS);
  const [tab_2_active_css, setTab_2_active_css] = useState("");
  const [tab_3_active_css, setTab_3_active_css] = useState("");

  const toggleTab = (id) => {
    if (id === 1) {
      setTab_1_Hide("");
      setTab_2_Hide("hidden");
      setTab_3_Hide("hidden");

      setTab_1_active_css(TAB_ACTIVE_CSS);
      setTab_2_active_css("");
      setTab_3_active_css("");
    }

    if (id === 2) {
      setTab_1_Hide("hidden");
      setTab_2_Hide("");
      setTab_3_Hide("hidden");

      setTab_1_active_css("");
      setTab_2_active_css(TAB_ACTIVE_CSS);
      setTab_3_active_css("");
    }

    if (id === 3) {
      setTab_1_Hide("hidden");
      setTab_2_Hide("hidden");
      setTab_3_Hide("");

      setTab_1_active_css("");
      setTab_2_active_css("");
      setTab_3_active_css(TAB_ACTIVE_CSS);
    }
  };

  /****** Right Pane - end ******************************************************************************************************************/

  return (
    <div className="flex h-full">
      <div id="left-pane" className="w-1/5 z-10">
        <div
          className={`bg-white absolute top-0 bottom-0 ${leftPaneStyle} transition-all duration-150 ease-in-out border-solid border-r border-slate-300`}
        >
          <div className="grid grid-cols-2">
            <div className="col-start-1 mt-1.5 ml-2.5">
              <p className="text-master_color-400 font-semibold text-lg font-sans drop-shadow mb-1.5">
                Route List
              </p>
            </div>
            <div className="col-start-2 flex justify-end">
              <button
                onClick={handleExpandLeftPane}
                className="text-master_color-400 font-bold rounded mt-1 mr-1"
              >
                {leftPaneExpandedButtonIcon}
              </button>
            </div>
            <div className="col-span-2 p-0"></div>
          </div>
          <hr className="border-slate-300 border-0.5 ml-0.5 mr-0.5"></hr>

          <div className={`h-[calc(100%_-_2.5rem)] p-2`}>
            <div className="ag-theme-quartz h-full">
              <AgGridReact
                ref={routeTableRef}
                columnDefs={routeTableColumnDefs}
                rowData={routeTableRowData}
                pagination={!paginationlHide}
                paginationPageSize={10}
                paginationPageSizeSelector={[5, 10, 15]}
                defaultColDef={useMemo(() => ({ suppressMovable: true }), [])}
                rowSelection={"single"}
                onSelectionChanged={handledOnRowClick}
                //onRowDoubleClicked={handledOnRowDoubleClick}
              />
            </div>
          </div>
        </div>
      </div>

      <div id="right-pane" className="w-4/5 z-0">
        <div id="button-container" className="flex gap-1.5 pl-2">
          <div className="mt-1">
            <button
              type="button"
              onClick={handleNewButton}
              className="px-4 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaPlus size={16} />
              &nbsp;New
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={routeStatus === "Active" ? false : true}
              onClick={handleSaveButton}
              className="px-4 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaSave size={16} />
              &nbsp;Save
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={operationMode === "new" ? true : false}
              onClick={handleDeleteButton}
              className="px-3 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaCircleXmark size={16} />
              &nbsp;Delete
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={operationMode === "new" ? true : false}
              onClick={handleActiveButton}
              className="w-28 px-3 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              {routeStatus === "Active" ? (
                <FaSquare size={12} color="red" />
              ) : (
                <FaCircle size={12} color="green" />
              )}
              &nbsp;
              {routeStatus === "Active" ? "Deactivate" : "Reactivate"}
            </button>
          </div>
          <div className="mt-2.5 ml-6 flex w-auto">
            <Notification response={apiResponse} />
          </div>
        </div>

        <hr className="border-slate-300 border-0.5 mt-1"></hr>

        <div id="tab-container" className="h-[calc(100%_-_6.5rem)]">
          <div
            id="route-info-tab-pane"
            className={`p-6 pt-1 ${tab_1_Hide}`}
            role="tabpanel"
          >
            <div className="grid grid-cols-3 gap-12">
              <div id="row-1-col-1">
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Basic
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">Route Name</div>
                  <div className="col-span-2">
                    <input
                      type="text"
                      name="name"
                      value={routeFormData.name}
                      onChange={handleBasicInputChange}
                      disabled={routeStatus === "Active" ? false : true}
                      placeholder="Required"
                      className={`w-full text-sm   pl-2 py-1 outline-none border  focus:border-master_color-200 rounded
                            ${
                              isValidName
                                ? "border-slate-400"
                                : "border-red-600"
                            }
                            ${
                              routeStatus === "Active"
                                ? "bg-white"
                                : "bg-gray-200"
                            } 
                            `}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">Code</div>
                  <div className="col-span-2">
                    <input
                      type="text"
                      name="code"
                      value={routeFormData.code}
                      onChange={handleBasicInputChange}
                      disabled={routeStatus === "Active" ? false : true}
                      className={`w-full text-sm   pl-2 py-1 outline-none border border-slate-400 focus:border-master_color-200 rounded                            
                            ${
                              routeStatus === "Active"
                                ? "bg-white"
                                : "bg-gray-200"
                            } 
                            `}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">
                    {/** label */}
                  </div>
                  <div className="col-span-2">{/** input */}</div>
                </div>
              </div>
              <div id="row-1-col-2"></div>
              <div id="row-1-col-3"></div>

              <div id="row-2-col-1">
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Overview
                </div>
                <div className="grid grid-cols-2 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Total Customer
                  </div>
                  <div className="flex pt-0.5"></div>
                </div>
                <div className="grid grid-cols-2 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Active Customer
                  </div>
                  <div className="flex"></div>
                </div>
                <div className="grid grid-cols-2 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Pending Customer
                  </div>
                  <div className="flex"></div>
                </div>
                <div className="grid grid-cols-2 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Declined Customer
                  </div>
                  <div className="flex"></div>
                </div>
              </div>

              <div id="row-2-col-2">
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Financial Performance
                </div>
                <div className="grid grid-cols-2 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Total Revenue
                  </div>
                  <div className="flex pt-0.5">Rs.</div>
                </div>
                <div className="grid grid-cols-2 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Pending Revenue
                  </div>
                  <div className="flex">Rs.</div>
                </div>
                <div className="grid grid-cols-2 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">
                    On Time Payment
                  </div>
                  <div className="flex">%</div>
                </div>
                <div className="grid grid-cols-2 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Overdue Payment
                  </div>
                  <div className="flex"></div>
                </div>
              </div>

              <div id="row-2-col-3">
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Route Performance
                </div>
                <div className="grid grid-cols-4 mt-1">
                  <div className="col-span-3 pt-0.5 font-sans drop-shadow">
                    Avg Revenue Per Customer
                  </div>
                  <div className="flex pt-0.5"></div>
                </div>
                <div className="grid grid-cols-4 mt-2">
                  <div className="col-span-3 pt-0.5 font-sans drop-shadow">
                    Route Growth Rate
                  </div>
                  <div className="flex"></div>
                </div>
                <div className="grid grid-cols-4 mt-2">
                  <div className="col-span-3 pt-0.5 font-sans drop-shadow">
                    Route Health
                  </div>
                  <div className="flex"></div>
                </div>
              </div>

              <div id="row-3-col-1">
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Conversion Metrics
                </div>
                <div className="grid grid-cols-2 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Samples Delivered
                  </div>
                  <div className="flex pt-0.5"></div>
                </div>
                <div className="grid grid-cols-2 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Conversion Rate
                  </div>
                  <div className="flex">%</div>
                </div>
              </div>

              <div id="row-3-col-2">
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Visit History
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="col-span-2 pt-0.5 font-sans drop-shadow">
                    Last Visit Date
                  </div>
                  <div className="flex pt-0.5"></div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="col-span-2 pt-0.5 font-sans drop-shadow">
                    Next Scheduled Visit Date
                  </div>
                  <div className="flex"></div>
                </div>
              </div>

              <div id="row-3-col-3"></div>
            </div>
          </div>

          <div
            id="customer-list-tab-pane"
            className={`p-4 pt-1 ${tab_2_Hide} h-full`}
            role="tabpanel"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15831.162623204676!2d80.59938464999999!3d7.2646493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2slk!4v1731697563874!5m2!1sen!2slk"
              width="800"
              height="400"
              title="hi"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div
            id="customer-list-tab-pane"
            className={`p-4 pt-1 ${tab_3_Hide} h-full`}
            role="tabpanel"
          >
            Customer List
          </div>
        </div>

        <div
          id="tab-button-container"
          className="mb-4 border-b border-gray-200"
        >
          <ul
            className="flex flex-wrap -mb-px text-sm font-medium text-center"
            id="default-styled-tab"
            data-tabs-toggle="#default-styled-tab-content"
            data-tabs-active-classes="text-master_color-400 hover:text-master_color-400 border-master_color-400 "
            data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300"
            role="tablist"
          >
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 drop-shadow-sm ${tab_1_active_css}`}
                id="profile-styled-tab"
                data-tabs-target="#styled-profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={() => toggleTab(1)}
              >
                Route Info
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4  border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 ${tab_2_active_css}`}
                id="dashboard-styled-tab"
                data-tabs-target="#styled-dashboard"
                type="button"
                role="tab"
                aria-controls="dashboard"
                aria-selected="false"
                onClick={() => toggleTab(0)}
              >
                Geo Data
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 ${tab_3_active_css}`}
                id="dashboard-styled-tab"
                data-tabs-target="#styled-dashboard"
                type="button"
                role="tab"
                aria-controls="dashboard"
                aria-selected="false"
                onClick={() => toggleTab(0)}
              >
                Customer List
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
