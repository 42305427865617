import {useNavigate } from "react-router-dom";
import '../css/Loading.css'
import { useEffect } from "react";

function Loading() {    

    const navigate = useNavigate();

    useEffect(() => { 
        const timeoutId = setTimeout(() => {
           navigate('/terminal')
        },4000);

        return () => clearTimeout(timeoutId);
    });

    return ( 
        <div className="bg-master_color-700">
            <div className='flex flex-col h-screen justify-center items-center'>
                <div className='h-1.5 w-full overflow-hidden flex flex-row justify-center items-center'>
                    <div className='progress w-full h-full bg-white left-right'></div>
                </div>
                <p className="font-medium text-lg text-gray-400">Loading...</p>                
            </div>  

        </div>
    );
}

export default Loading;