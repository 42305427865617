import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL+'/user';

export const loginService = async(loginRequest) => {
    let data = null;
    try{
        data = await axios.post(API_BASE_URL+'/login',loginRequest);        
    }catch(error){             
        console.log('UserService -> loginService: '+error);
    }finally{
        return data;
    }


}