import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL+'/category';

export const getCategoryList = async(token) => {
    let categoryList = null
    try{
        categoryList = await axios.get(API_BASE_URL,{ 
            headers: {                                                                                                   
                    'Authorization': `Bearer ${token}`                                                   
                    }            
            });            
        return categoryList.data;
    }catch(error){             
        console.log('CategoryService -> getCategoryList: '+error);
    }
}

export const saveCategory = async(token,category) => {
    let response = null   
    try{
        response = await axios.post(API_BASE_URL,category,{ 
            headers: {                                                                
                    'Authorization': `Bearer ${token}`                                  
                    }
            });   
            
    }catch(error){  
        response = error.response;                  
        console.log('CategoryService -> saveCategory: '+error);
    }finally {
        return response.data;
    }
}

export const updateCategory = async(token,category) => {
    let response = null   
    try{
        response = await axios.put(API_BASE_URL+'/'+category.id,category,{ 
            headers: {                                                                
                    'Authorization': `Bearer ${token}`                                  
                    }
            });   
            
    }catch(error){  
        response = error.response;                  
        console.log('CategoryService -> updateCategory: '+error);
    }finally {
        return response.data;
    }
}

export const deleteCategory = async(token,categoryId) => {
    let response = null   
    try{
        response = await axios.delete(API_BASE_URL+'/'+categoryId,{ 
            headers: {                                                                
                    'Authorization': `Bearer ${token}`                                  
                    }
            });   
            
    }catch(error){  
        response = error.response;                  
        console.log('CategoryService -> deleteCategory: '+error);
    }finally {
        return response.data;
    }
}

export const getAllActiveCategory = async(token) => {
    let categoryList = null
    try{
        categoryList = await axios.get(API_BASE_URL+'/active/basic',{ 
            headers: {                                                                                                   
                    'Authorization': `Bearer ${token}`                                                   
                    }            
            });            
        return categoryList.data;
    }catch(error){             
        console.log('CategoryService -> getActiveCategoryList: '+error);
    }
}

export const getItemListByCategoryId = async(token,categoryId) =>{
    let itemList = null;
    try {
        itemList = await axios.get(API_BASE_URL+'/'+categoryId+'/items',{ 
            headers: {                                                                                                   
                    'Authorization': `Bearer ${token}`                                                   
                    }            
            });            
        return itemList.data;
    } catch (error) {
        console.log('CategoryService -> getItemListByCategoryId: '+error);
    }
}