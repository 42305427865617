import {FaSquareCheck, FaSquareXmark  } from "react-icons/fa6";
import { MdOutlineErrorOutline,MdOutlineCheckCircleOutline  } from "react-icons/md";

function Notification({response}) {

    if(response.status === "" && response.message !== ''){
        return (
            <div className='flex'>
                <MdOutlineCheckCircleOutline  size={21} className='text-master_color-400 drop-shadow '/>
                <p className='ml-1.5  font-medium text-gray-500 drop-shadow text-sm'>{response.message}</p>
            </div>
            
        );
    }
    
    if(response.status === true && response.message !== ''){
        return(
            <div className='flex'>
                <MdOutlineCheckCircleOutline  size={21} className='text-green-500 drop-shadow'/>
                <p className='ml-1.5  font-medium text-gray-500 drop-shadow text-sm'>{response.message}</p> 
            </div>            
        );
    }else if(response.status === false && response.message !== ''){
        return(
            <div className='flex'>
                <MdOutlineErrorOutline  size={21} className='text-red-600 drop-shadow'/>
                <p className='ml-1.5 mt-0 font-medium text-gray-500 drop-shadow text-sm'>{response.message}</p>
            </div>             
        );
    }
    
}

export default Notification;

//{response.status ?
//    <FaCircleCheck size={16} className='text-green-500'/>                           
//    :
//    <FaCircleExclamation size={16} className='text-red-600'/>
//}  
//<p className='ml-1.5 -mt-0.5 font-medium text-master_color-400 text-sm'>{response.message}</p>