import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL+'/route';

export const getRouteList = async(token) => {
  let list = null
  try{
    list = await axios.get(API_BASE_URL+'?status=All',{ 
          headers: {                                                                                                   
                  'Authorization': `Bearer ${token}`                                                   
                  }            
          });            
      return list.data;
  }catch(error){             
      console.log('RouteService -> getRouteList: '+error);
  }
}

export const getActiveRouteList = async(token) => {
    let list = null
    try{
      list = await axios.get(API_BASE_URL+'?status=Active',{ 
            headers: {                                                                                                   
                    'Authorization': `Bearer ${token}`                                                   
                    }            
            });            
        return list.data;
    }catch(error){             
        console.log('RouteService -> getActiveRouteList: '+error);
    }
}

export const saveRoute = async(token,route) => {
  let response = null   
  try{
      response = await axios.post(API_BASE_URL,route,{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                  
      console.log('RouteService -> saveRoute: '+error);
  }finally {
      return response.data;
  }
}

export const updateRoute = async(token,route) => {
  let response = null   
  try{
      response = await axios.put(API_BASE_URL+'/'+route.id,route,{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                  
      console.log('RouteService -> updateRoute: '+error);
  }finally {
      return response.data;
  }
}

export const deleteRoute = async(token,routeId) => {
  let response = null   
  try{
      response = await axios.delete(API_BASE_URL+'/'+routeId,{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                  
      console.log('RouteService -> deleteRoute: '+error);
  }finally {
      return response.data;
  }
} 

export const deActivateRoute = async(token,routeId) => {
  let response = null   
  try{        
      response = await axios.put(API_BASE_URL+'/deactivate/'+routeId,'',{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                
      console.log('RouteService -> deActivateRoute: '+error);
  }finally {
      return response.data;
  }
}

export const activateRoute = async(token,routeId) => {
  let response = null   
  try{
      response = await axios.put(API_BASE_URL+'/activate/'+routeId,'',{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                
      console.log('RouteService -> activateRoute: '+error);
  }finally {
      return response.data;
  }
}