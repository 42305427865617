import {Outlet} from "react-router-dom";
import Footer from "../component/footer/Footer";
import Header from "../component/header/Header";


function Terminal() {    

    return (          
        <div className="flex flex-col h-screen">
            <Header/>                                          
            <section className="relative flex-grow">            
                <Outlet/>                                                                                   
            </section>
            <Footer/>                                    
        </div>           
        
    );
}

export default Terminal;