import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL+'/item';

export const getItemList = async(token) => {
    let itemList = null
    try{
        itemList = await axios.get(API_BASE_URL,{ 
            headers: {                                                                                                   
                    'Authorization': `Bearer ${token}`                                                   
                    }            
            }); 
            
        return itemList.data;
    }catch(error){             
        console.log('ItemService -> getItemList: '+error);
    }
}

export const saveItem = async(token,catId,item) => {
    let response = null   
    try{
        response = await axios.post(API_BASE_URL+'?catId='+catId,item,{ 
            headers: {                                                                
                    'Authorization': `Bearer ${token}`                                  
                    }
            });   
            
    }catch(error){  
        response = error.response;                  
        console.log('ItemService -> saveItem: '+error);
    }finally {
        return response.data;
    }
}

export const updateItem = async(token,catId,item) => {
    let response = null   
    try{
        response = await axios.put(API_BASE_URL+'/'+item.id+'?catId='+catId,item,{ 
            headers: {                                                                
                    'Authorization': `Bearer ${token}`                                  
                    }
            });   
            
    }catch(error){  
        response = error.response;                  
        console.log('ItemService -> updateItem: '+error);
    }finally {
        return response.data;
    }
}

export const getItemById = async(token,itemId) => {
    let item = null
    try{
        item = await axios.get(API_BASE_URL+'/'+itemId,{ 
            headers: {                                                                                                   
                    'Authorization': `Bearer ${token}`                                                   
                    }            
            }); 
            
        return item.data;
    }catch(error){                   
        console.log('ItemService -> getItemById: '+error);
        return null;
    }
}

export const deActivateItem = async(token,itemId) => {
    let response = null   
    try{        
        response = await axios.put(API_BASE_URL+'/deactivate/'+itemId,'',{ 
            headers: {                                                                
                    'Authorization': `Bearer ${token}`                                  
                    }
            });   
            
    }catch(error){  
        response = error.response;                
        console.log('ItemServoce -> deActivateItem: '+error);
    }finally {
        return response.data;
    }
}

export const activateItem = async(token,itemId) => {
    let response = null   
    try{
        response = await axios.put(API_BASE_URL+'/activate/'+itemId,'',{ 
            headers: {                                                                
                    'Authorization': `Bearer ${token}`                                  
                    }
            });   
            
    }catch(error){  
        response = error.response;                
        console.log('ItemService -> activateItem: '+error);
    }finally {
        return response.data;
    }
}


