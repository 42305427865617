import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL + "/customer";

export const getCustomerList = async (token) => {
  let list = null;
  try {
    list = await axios.get(API_BASE_URL + "?status=All", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    return list.data;
  } catch (error) {
    console.log("CustomerService -> getCustomerList: " + error);
  }
};

export const saveCustomer = async (token, customer) => {
  let response = null;
  try {
    response = await axios.post(API_BASE_URL, customer, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    response = error.response;
    console.log("CustomerService -> saveCustomer: " + error);
  } finally {
    return response.data;
  }
};

export const updateCustomer = async (token, customer) => {
  let response = null;
  try {
    response = await axios.put(API_BASE_URL + "/" + customer.id, customer, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    response = error.response;
    console.log("CustomerService -> updateCustomer: " + error);
  } finally {
    return response.data;
  }
};

export const deleteCustomer = async (token, customerId) => {
  let response = null;
  try {
    response = await axios.delete(API_BASE_URL + "/" + customerId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    response = error.response;
    console.log("CustomerService -> deleteCustomer: " + error);
  } finally {
    return response.data;
  }
};

export const deActivateCustomer = async (token, customerId) => {
  let response = null;
  try {
    response = await axios.put(API_BASE_URL +"/"+ customerId+"/deactivate" , "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    response = error.response;
    console.log("CustomerService -> deActivateCustomer: " + error);
  } finally {
    return response.data;
  }
};

export const activateCustomer = async (token, customerId) => {
  let response = null;
  try {
    response = await axios.put(API_BASE_URL + "/"+ customerId+"/activate", "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    response = error.response;
    console.log("CustomerService -> activateCustomer: " + error);
  } finally {
    return response.data;
  }
};

export const getNewCustomerCode = async (token) => {
  let res = null;
  try {
    res = await axios.get(API_BASE_URL + "/generate/customerCode", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });    
    return res.data;
  } catch (error) {
    console.log("CustomerService -> getNewCustomerCode: " + error);
  }
};