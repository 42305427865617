import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL+'/plan';

export const getPlanList = async(token) => {
  let planList = null
  try{
      planList = await axios.get(API_BASE_URL,{ 
          headers: {                                                                                                   
                  'Authorization': `Bearer ${token}`                                                   
                  }            
          });            
      return planList.data;
  }catch(error){             
      console.log('PlanService -> getPlanList: '+error);
  }
}

export const getAllActivePlanList = async(token) => {
    let planList = null
    try{
        planList = await axios.get(API_BASE_URL+'/active',{ 
            headers: {                                                                                                   
                    'Authorization': `Bearer ${token}`                                                   
                    }            
            });            
        return planList.data;
    }catch(error){             
        console.log('PlanService -> getActivePlanList: '+error);
    }
}

export const savePlan = async(token,plan) => {
  let response = null   
  try{
      response = await axios.post(API_BASE_URL,plan,{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                  
      console.log('PlanService -> savePlan: '+error);
  }finally {
      return response.data;
  }
}

export const updatePlan = async(token,plan) => {
  let response = null   
  try{
      response = await axios.put(API_BASE_URL+'/'+plan.id,plan,{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                  
      console.log('PlanService -> updatePlan: '+error);
  }finally {
      return response.data;
  }
}

export const deletePlan = async(token,planId) => {
  let response = null   
  try{
      response = await axios.delete(API_BASE_URL+'/'+planId,{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                  
      console.log('PlanService -> deletePlan: '+error);
  }finally {
      return response.data;
  }
} 

export const deActivatePlan = async(token,planId) => {
  let response = null   
  try{        
      response = await axios.put(API_BASE_URL+'/deactivate/'+planId,'',{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                
      console.log('PlanServoce -> deActivatePlan: '+error);
  }finally {
      return response.data;
  }
}

export const activatePlan = async(token,planId) => {
  let response = null   
  try{
      response = await axios.put(API_BASE_URL+'/activate/'+planId,'',{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                
      console.log('PlanService -> activatePlan: '+error);
  }finally {
      return response.data;
  }
}

export const getPlanValueListByPlanId = async(token,planId) => {
    let planValueList = null
    try{
        planValueList = await axios.get(API_BASE_URL+'/'+planId+'/plan_values',{ 
            headers: {                                                                                                   
                    'Authorization': `Bearer ${token}`                                                   
                    }            
            });            
        return planValueList.data;
    }catch(error){             
        console.log('PlanService -> getPlanValueListByPlanId: '+error);       
    }
}

