import { Link } from 'react-router-dom'
import { FaHome, FaPeopleArrows } from "react-icons/fa";
import { FaDisplay, FaPeopleRoof, FaRoad } from "react-icons/fa6";

const navigation = [
    { name: 'Home', to: '/terminal/home', icon: <FaHome size={24}/>, active: true},
    { name: 'Dashboard', to: '/terminal/dashboard', icon: <FaDisplay size={24}/>, active: true},
    { name: 'Collector', to: '/terminal/collector', icon: <FaPeopleArrows size={24}/>, active: false},
    { name: 'Route', to: '/terminal/route', icon: <FaRoad size={24}/>, active: true},   
    { name: 'Customer', to: '/terminal/customer', icon: <FaPeopleRoof size={24}/>, active: true},
]

function NavLink() {

    return ( 
        <div className="top-nav inline-flex flex-grow w-auto pr-2">
            <div className="inline-flex flex-row ml-auto">
            {navigation.map((item) => (
                <Link
                    key={item.name}
                    to={item.to}
                    className={`inline-flex w-24 px-3 py-1 rounded text-gray-300 hover:text-master_color-600 hover:bg-gray-300 flex-col items-center ${item.active ? '':'hidden'}`}
                >
                    {item.icon}
                    <span className="tracking-wider font-semibold uppercase text-xs mt-1">{item.name}</span>
                </Link>
            ))}
            </div>
        </div>
     );
}

export default NavLink;

