import Logo from "./Logo"
import NavLink from "./NavLink";


function NavBar() {
    return ( 
        <div className="flex items-center bg-master_color-600 h-16 w-auto flex-wrap">
            <Logo/>
            <NavLink/>
        </div>
     );
}

export default NavBar;