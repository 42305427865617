import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL+'/planValue';

export const savePlanValue = async(token,planId,planValue) => {
  let response = null   
  try{
      response = await axios.post(API_BASE_URL+'?planId='+planId,planValue,{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                  
      console.log('PlanValueService -> savePlanValue: '+error);
  }finally {
      return response.data;
  }
}
